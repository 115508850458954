import { combineReducers } from 'redux'
import { router5Reducer, RouterState } from 'redux-router5'

import sessionReducer, { SessionState } from 'src/pages/connect/mailchimp/session'
import listReducer, { ListState } from 'src/pages/connect/list/reducer'
import accountReducer, { AccountState } from 'src/pages/settings/reducer'

interface StoreEnhancerState { }

export interface RootState extends StoreEnhancerState {
  router: RouterState,
  session: SessionState,
  list: ListState,
  account: AccountState,
}

export const RootReducer = combineReducers<RootState>({
  router: router5Reducer,
  session: sessionReducer,
  list: listReducer,
  account: accountReducer,
})
