import * as React from 'react'
import { unit } from '../utils/css'
import { ComposeLayoutable } from './layoutable'

declare type PropSize = false |
  'none' |
  'xxx-small' |
  'xx-small' |
  'x-small' |
  'small' |
  'medium' |
  'large' |
  'x-large' |
  'xx-large' |
  'xxx-large'

export interface PropsTypography {
  color?: 'error' | 'gray' | 'white' | 'alphaWhite' | 'black',
  bold?: boolean,
  italic?: boolean,
  size?: PropSize
}

const fontSizeMap: { [key: string]: string } = {
  'xxx-small': unit(8 / 16, 'rem'),
  'xx-small': unit(10 / 16, 'rem'),
  'x-small': unit(12 / 16, 'rem'),
  small: unit(14 / 16, 'rem'),
  medium: unit(16 / 16, 'rem'),
  large: unit(18 / 16, 'rem'),
  'x-large': unit(20 / 16, 'rem'),
  'xx-large': unit(22 / 16, 'rem'),
  'xxx-large': unit(24 / 16, 'rem'),
}

const lineHeightMap: { [key: string]: string } = {
  'xxx-small': unit(12 / 16, 'rem'),
  'xx-small': unit(15 / 16, 'rem'),
  'x-small': unit(18 / 16, 'rem'),
  small: unit(21 / 16, 'rem'),
  medium: unit(23 / 16, 'rem'),
  large: unit(25 / 16, 'rem'),
  'x-large': unit(28 / 16, 'rem'),
  'xx-large': unit(30 / 16, 'rem'),
  'xxx-large': unit(32 / 16, 'rem'),
}

const colorLookup = {
  error: '#FF1744',
  gray: '#757575',
  white: '#FFFFFF',
  black: '#000000',
  alphaWhite: 'rgba(255, 255, 255, 0.7)',
}

const stylesForProps = (props: PropsTypography) => {
  const style: React.CSSProperties = {
    fontWeight: props.bold ? 700 : 400,
    fontSize: '1rem',
    lineHeight: unit(23 / 16, 'rem'),
    fontStyle: props.italic ? 'italic' : 'inherit',
  }

  if (props.color) {
    style.color = colorLookup[props.color]
  }

  if (props.size) {
    style.fontSize = fontSizeMap[props.size]
    style.lineHeight = lineHeightMap[props.size]
  }

  return style
}

export const Paragraph = ComposeLayoutable<PropsTypography>(
  (props: PropsTypography & { style: object; children?: React.ReactNode }) => (
    <p style={{ ...stylesForProps(props), ...props.style }}>
      {props.children}
    </p>
  ))

export const Span = ComposeLayoutable<PropsTypography>(
  (props: PropsTypography & { style: object; children?: React.ReactNode }) => (
    <span style={{ display: 'inline-block', ...stylesForProps(props), ...props.style }}>
      {props.children}
    </span>
  ))
