import createRouter, { loggerPlugin } from 'router5'
import browserPlugin from 'router5/plugins/browser'
import persistentParams from 'router5/plugins/persistentParams'

const routes = [
  { name: 'connect', path: '/connect' },
  { name: 'connect.list', path: '/list' },
  { name: 'connect.new', path: '/new' },
  { name: 'connect.mailchimp', path: '/mailchimp' },
  { name: 'connect.mailchimp.complete', path: '/complete' },
  { name: 'connect.mailchimp.error', path: '/error' },
  { name: 'settings', path: '/settings' },
  { name: 'disconnect', path: '/disconnect' },
]

const plugins = [
  browserPlugin(),
  persistentParams(['token', 'hmac', 'timestamp', 'tenant']),
]

if (process.env.NODE_ENV === 'development') {
  plugins.push(loggerPlugin)
}

const router = createRouter(routes, { defaultRoute: 'connect' })
  .usePlugin(...plugins)

export default router
