import MaterialButton from '@material-ui/core/Button'
import { StyleRules, withStyles, WithStyles } from '@material-ui/core/styles'
import * as React from 'react'

import { unit } from '../utils/css'
import { layoutableStyle, PropsLayoutable } from './layoutable'

export type PropsButton = PropsLayoutable & {
  disabled?: boolean
  onClick?: React.MouseEventHandler<{}>
  fullWidth?: boolean
}

const buttonStyle = (props: PropsButton, style: React.CSSProperties): React.CSSProperties => {
  if (props.fullWidth) {
    // Magic to center the overflowing button inside its parent
    style.marginLeft = '-100%'
    style.marginRight = '-100%'

    style.minWidth = '100%'
  }

  return style
}

const sizeStandardHeight = 43
const sizeBorderThick = 3
const sizeBorderThin = 1

const StyleCommon: StyleRules = {
  root: {
    fontWeight: 700,
    fontSize: unit(12 / 16, 'rem'),
    lineHeight: unit(sizeStandardHeight / 16, 'rem'),
    minHeight: unit(sizeStandardHeight / 16, 'rem'),
    padding: `0 ${unit(20 / 16, 'rem')}`,
    minWidth: 'unset',
    ['&:hover' as any]: {
      background: 'rgba(0, 0, 0, 0.05) !important',
    },
  },
  flatPrimary: {
    lineHeight: unit((sizeStandardHeight - (sizeBorderThick * 2)) / 16, 'rem'),
    minHeight: unit((sizeStandardHeight - (sizeBorderThick * 2)) / 16, 'rem'),
    color: '#757575',
    border: `${unit(sizeBorderThick, 'px')} solid rgba(0, 0, 0, 0.2)`,
    background: 'transparent',
    ['&:hover' as any]: {
      background: 'rgba(255, 255, 255, 0.1) !important',
    },
  },
  raised: {
    boxShadow: `0 ${unit(2 / 12, 'rem')} ${unit(2 / 16, 'rem')} rgba(0, 0, 0, 0.2)`,
    ['&:hover' as any]: {
      background: 'rgb(230, 153, 0) !important',
    },
  },
  raisedPrimary: {
    color: '#ffffff',
    background: 'rgb(255, 171, 0)',
    ['&:hover' as any]: {
      background: 'rgb(230, 153, 0) !important',
    },
  },
  label: {
    letterSpacing: '1px',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
}

type ButtonProps = PropsButton & WithStyles<string> & { children?: React.ReactNode }
const buttonEnhancer = withStyles(StyleCommon, { name: 'Button' })

export const PrimaryButton = buttonEnhancer<PropsButton>((props: ButtonProps) => (
  <MaterialButton
    variant="raised"
    color="primary"
    classes={props.classes}
    style={buttonStyle(props, layoutableStyle(props))}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    {props.children}
  </MaterialButton>
))

export const SecondaryButton = buttonEnhancer<PropsButton>((props: ButtonProps) => (
  <MaterialButton
    color="inherit"
    classes={props.classes}
    style={{ ...buttonStyle(props, layoutableStyle(props)), color: '#000' }}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    {props.children}
  </MaterialButton>
))

export const Button = buttonEnhancer<PropsButton>((props: ButtonProps) => (
  <MaterialButton
    color="primary"
    classes={props.classes}
    style={buttonStyle(props, layoutableStyle(props))}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    {props.children}
  </MaterialButton>
))

export const ContrastButton = buttonEnhancer<PropsButton>((props: ButtonProps) => {
  const overrides = {
    color: '#ffffff',
    lineHeight: unit((sizeStandardHeight - (sizeBorderThin * 2)) / 16, 'rem'),
    minHeight: unit((sizeStandardHeight - (sizeBorderThin * 2)) / 16, 'rem'),
    border: `${unit(sizeBorderThin, 'px')} solid #ffffff`,
    background: 'transparent',
  }
  return (
    <MaterialButton
      color="inherit"
      classes={props.classes}
      style={{ ...buttonStyle(props, layoutableStyle(props)), ...overrides }}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </MaterialButton>
  )
})
