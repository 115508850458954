import * as React from 'react'
import { connect } from 'react-redux'
import { actions, routeNodeSelector } from 'redux-router5'

import { PrimaryButton } from 'src/components/button'
import { Layoutable } from 'src/components/layoutable'
import Logo from 'src/components/logo'
import { Paragraph } from 'src/components/typography'

type PageProps = {
  handleCreateMailChimpAccount: () => void
}

const PageCreateAccount: React.SFC<PageProps> = props => (
  <Layoutable align="center">
    <Logo />
    <Paragraph paddingVertical="small" color="alphaWhite">
      After creating a MailChimp account from the link below, return back
      to this window to finish connecting your Volusion store.
    </Paragraph>
    <Layoutable marginTop="x-large" paddingHorizontal="xxx-large">
      <PrimaryButton fullWidth onClick={props.handleCreateMailChimpAccount}>
        Create Mailchimp Account
      </PrimaryButton>
    </Layoutable>
  </Layoutable>
)

export default connect(
  _state => routeNodeSelector('connect.new'),
  { navigateTo: actions.navigateTo },
  (_stateProps, dispatchProps, _ownProps) => ({
    handleCreateMailChimpAccount: () => {
      window.open('https://login.mailchimp.com/signup?source=website&pid=volusion', '_blank')
      dispatchProps.navigateTo('connect', {}, { replace: true })
    },
  })
)(PageCreateAccount)
