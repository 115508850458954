import * as React from 'react'
import { connect } from 'react-redux'
import { routeNodeSelector } from 'redux-router5'
import { RootState } from 'src/store'
import { H3 } from 'src/components/headers'
import { Layoutable } from 'src/components/layoutable'
import Logo from 'src/components/logo'
import { Paragraph, Span } from 'src/components/typography'

type InjectedProps = {
  path: string
}

const Page404: React.SFC<InjectedProps> = (props) => (
  <Layoutable align="center">
    <Logo />
    <H3 marginBottom="xx-small">This isn’t the thing you’re looking for.</H3>
    {props.path &&
      <Paragraph paddingVertical="small" color="alphaWhite">
        We can’t find <Span color="white" bold>{props.path}</Span>.
        Please use the navigation above.
      </Paragraph>}
  </Layoutable>
)

const mapStateToProps = (state: RootState) => {
  const routerProps = routeNodeSelector('')(state)
  const route = routerProps.route

  return {
    path: route ? route.path : '',
  }
}

export default connect(mapStateToProps)(Page404)
