import { AxiosResponse } from 'axios'
import { put, takeLatest } from 'redux-saga/effects'
import apiClient from 'src/apiClient'
import actions, { types, Session } from './actions'

export function* callSession(_action: ReturnType<typeof actions.session.call>) {
  yield put(actions.session.request())

  try {
    const { data }: AxiosResponse<Session> = yield apiClient.get('/session')

    yield put(actions.session.ok(data))
  } catch (e) {
    yield put(actions.session.error(e))
  }
}

export function* session() {
  yield takeLatest(types.SESSION, callSession)
}
