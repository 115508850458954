import * as React from 'react'
import { connect } from 'react-redux'
import { RouterState, routeNodeSelector } from 'redux-router5'

// content components
import Mailchimp from 'src/pages/connect/mailchimp/landing'
import CompletePage from 'src/pages/connect/mailchimp/complete'
import ErrorPage from 'src/pages/connect/mailchimp/error'
import NotFound from 'src/pages/404'

const components = {
  complete: CompletePage,
  error: ErrorPage,
}

const RoutedComponent: React.SFC<RouterState> = (props) => {
  const { route } = props
  const segments = route!.name.split('.').slice(2)
  if (segments.length === 0) {
    return <Mailchimp />
  }

  const Component = components[segments[0]] || NotFound
  return <Component />
}

export default connect(_state => routeNodeSelector('connect.mailchimp'))(RoutedComponent)
