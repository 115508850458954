import * as React from 'react'
import glamorous from 'glamorous'
import { connect } from 'react-redux'
import { RouterState, routeNodeSelector } from 'redux-router5'
import Layout from 'src/components/layout'
import { RootState } from 'src/store'

// content components
import Connect from 'src/pages/connect'
import Settings from 'src/pages/settings'
import NotFound from 'src/pages/404'

const components = {
  connect: Connect,
  settings: Settings,
}

const Wrapper = glamorous.div({
  display: 'flex',
  overflowY: 'scroll',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
})

type PassedProps = RouterState & {
  withHeader: boolean
}

const App: React.SFC<PassedProps> = (props) => {
  const { route, withHeader } = props
  const segment = route!.name.split('.')[0]
  const Component = components[segment] || NotFound
  return (
    <Wrapper>
      <Layout withHeader={withHeader}>
        <Component />
      </Layout>
    </Wrapper>
  )
}

const mapStateToProps = (state: RootState) => {
  const routerProps = routeNodeSelector('')(state)
  const { name } = routerProps.route!
  const withHeader = name === 'settings'
    || name === 'connect.list'

  return {
    ...routerProps,
    withHeader,
  }
}

export default connect(mapStateToProps)(App)
