import * as React from 'react'
import glamorous, { CSSProperties } from 'glamorous'

import { unit } from 'src/utils/css'

interface PropsLayout {
  withHeader?: boolean
}

const LayoutWrapper = glamorous.div(
  {
    width: '100%',
    flex: '1 0 auto',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    background: '#52BAD5',
    fontFamily: 'Lato, sans-serif',
    fontSize: '16px',
    lineHeight: unit(19 / 16, 'rem'),
    '@media(min-width: 768px) and (-webkit-min-device-pixel-ratio: 2)': {
      fontSize: '20px',
      lineHeight: unit(28 / 20, 'rem'),
    },
    '@media(min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2)': {
      fontSize: '30px',
      lineHeight: unit(38 / 30, 'rem'),
    },
  },
  (props: PropsLayout): CSSProperties[] => {
    const styles: CSSProperties[] = []

    if (props.withHeader) {
      styles.push({
        paddingTop: '55px',
        paddingBottom: '55px',
        background: '#F2F2F2',
        '::before': {
          position: 'absolute',
          background: '#52bad4',
          left: 0,
          right: 0,
          top: 0,
          height: 110,
          content: ' ',
        },
      })
    } else {
      styles.push({
        alignItems: 'center',
        justifyContent: 'center',
      })
    }

    return styles
  }
)

const LayoutContainerHeader = glamorous.div({
  position: 'relative',
  maxWidth: 800,
  width: '90%',
})

const LayoutContainerCentered = glamorous.div({
  maxWidth: 450,
  width: '80%',
  margin: '0px auto',
  '@media only screen and (max-width : 767px) and (-webkit-min-device-pixel-ratio: 2)': {
    maxWidth: 300,
  },
  '@media only screen and (min-width: 768px) and (max-width : 1023px) and (-webkit-min-device-pixel-ratio: 2)': {
    maxWidth: 550,
  },
  '@media only screen and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2)': {
    maxWidth: 850,
  },
})

const Layout: React.SFC<PropsLayout> = props => (
  <LayoutWrapper {...props}>
    {props.withHeader ? (
      <LayoutContainerHeader>
        {props.children}
      </LayoutContainerHeader>
    ) : (
        <LayoutContainerCentered>
          {props.children}
        </LayoutContainerCentered>
      )}
  </LayoutWrapper>
)

export default Layout
