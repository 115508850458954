import { ShopperList } from '../connect/list/actions'

const ACCOUNT = 'ACCOUNT'
const ACCOUNT_REQUEST = 'ACCOUNT_REQUEST'
const ACCOUNT_REQUEST_ERROR = 'ACCOUNT_REQUEST_ERROR'
const ACCOUNT_REQUEST_OK = 'ACCOUNT_REQUEST_OK'

export type Account = {
  list: ShopperList
  isSynced: boolean
  connectedAt: Date
}

type ActionAccount = {
  type: typeof ACCOUNT
}

type ActionAccountRequest = {
  type: typeof ACCOUNT_REQUEST
}

type ActionAccountRequestError = {
  type: typeof ACCOUNT_REQUEST_ERROR
  payload: Error
}

type ActionAccountRequestOk = {
  type: typeof ACCOUNT_REQUEST_OK
  payload: Account
}

export const types = {
  ACCOUNT,
  ACCOUNT_REQUEST,
  ACCOUNT_REQUEST_ERROR,
  ACCOUNT_REQUEST_OK,
}

export type Actions = ActionAccount
  | ActionAccountRequest
  | ActionAccountRequestError
  | ActionAccountRequestOk

const actionCreators = {
  account: {
    call: (): ActionAccount => ({
      type: ACCOUNT,
    }),
    request: (): ActionAccountRequest => ({
      type: ACCOUNT_REQUEST,
    }),
    error: (error: Error): ActionAccountRequestError => ({
      type: ACCOUNT_REQUEST_ERROR,
      payload: error,
    }),
    ok: (account: Account) => ({
      type: ACCOUNT_REQUEST_OK,
      payload: account,
    }),
  },
}

export default actionCreators
