import actions, {
  ShopperList,
  Actions,
  types,
} from './actions'

export type ListState = {
  lists?: ReadonlyArray<ShopperList>
  isConnecting: boolean
  isConnected: boolean
  error?: Error
}

const initialState: ListState = {
  lists: undefined,
  isConnecting: false,
  isConnected: false,
  error: undefined,
}

const reducer = (state: ListState = initialState, action: Actions): ListState => {
  const { type } = action
  switch (type) {
    case types.LIST_REQUEST: {
      return {
        ...state,
        lists: undefined,
      }
    }
    case types.LIST_REQUEST_ERROR: {
      const act = action as ReturnType<typeof actions.lists.error>
      return {
        ...state,
        error: act.payload,
      }
    }
    case types.LIST_REQUEST_OK: {
      const act = action as ReturnType<typeof actions.lists.ok>
      return {
        ...state,
        lists: act.payload,
      }
    }
    case types.CONNECT_REQUEST: {
      return {
        ...state,
        isConnecting: true,
      }
    }
    case types.CONNECT_REQUEST_ERROR: {
      const act = action as ReturnType<typeof actions.connect.error>
      return {
        ...state,
        error: act.payload,
        isConnecting: false,
      }
    }
    case types.CONNECT_REQUEST_OK: {
      return {
        ...state,
        isConnected: true,
        isConnecting: false,
        error: undefined,
      }
    }
    default:
      return state
  }
}

export default reducer
