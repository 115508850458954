import { AxiosResponse } from 'axios'
import { all, put, takeEvery, takeLatest } from 'redux-saga/effects'
import apiClient, { errorFromResponse } from 'src/apiClient'

import actions, {
  types,
  ShopperList,
} from './actions'

export function* callList(_action: ReturnType<typeof actions.lists.call>) {
  yield put(actions.lists.request())

  try {
    const { data }: AxiosResponse<ReadonlyArray<ShopperList>> = yield apiClient.get('/api/lists')

    yield put(
      actions.lists.ok(data)
    )
  } catch (err) {
    yield put(actions.lists.error(errorFromResponse(err)))
  }
}

export function* list() {
  yield takeLatest(types.LIST, callList)
}

export function* callConnect(action: ReturnType<typeof actions.connect.call>) {
  yield put(actions.connect.request())

  try {
    const { id: listId, name: listName, syncShoppers } = action.payload
    yield apiClient.post('/api/connect', { listId, listName, syncShoppers })
    yield put(actions.connect.ok())
  } catch (err) {
    yield put(actions.connect.error(errorFromResponse(err)))
  }
}

export function* connect() {
  yield takeEvery(types.CONNECT, callConnect)
}

export default function* rootSaga() {
  yield all([
    list(),
    connect(),
  ])
}
