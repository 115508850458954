import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router5'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import 'reset-css/reset.css'

import router from 'src/router'
import configureStore from 'src/store'
import App from 'src/app'

const store = configureStore(router)

const colorPrimary = {
  50: '#fff8e1',
  100: '#ffecb3',
  200: '#ffe082',
  300: '#ffd54f',
  400: '#ffca28',
  500: '#ffc107',
  600: '#ffb300',
  700: '#ffa000',
  800: '#ff8f00',
  900: '#ff6f00',
  A100: '#ffe57f',
  A200: '#ffd740',
  A400: '#ffc400',
  A700: '#ffab00',
  contrastDefaultColor: 'dark',
}

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: 'Lato, sans-serif',
  },
  palette: {
    primary: colorPrimary,
  },
})

const WrappedApp = () => (
  <Provider store={store}>
    <RouterProvider router={router}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        <App />
      </MuiThemeProvider>
    </RouterProvider>
  </Provider>
)

router.start((_err, _state) => {
  ReactDOM.render(<WrappedApp />, document.getElementById('app'))
})
