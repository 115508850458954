import axios, { AxiosResponse, AxiosError } from 'axios'
import * as qs from 'query-string'

const client = axios.create()

export type ErrorReponse = {
  error: string
}

type MaybeErrorResponse = AxiosResponse<ErrorReponse> | undefined

export function errorFromResponse(err: AxiosError): Error {
  const res: MaybeErrorResponse = err.response
  const msg = res ? res.data.error : 'Unknown Error'
  return new Error(msg)
}

// add sessionId interceptor
client.interceptors.request.use((cfg) => {
  const search = qs.parse(document.location.search)
  const { token } = search
  if (!token) {
    return cfg
  }

  return {
    ...cfg,
    headers: {
      ...cfg.headers,
      Authorization: `Bearer ${token}`,
    },
  }
})

export default client
