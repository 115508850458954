import * as React from 'react'
import { connect } from 'react-redux'
import { RouterState, actions, routeNodeSelector } from 'redux-router5'

import { ContrastButton, PrimaryButton } from 'components/button'
import { H3 } from 'components/headers'
import { Layoutable } from 'components/layoutable'
import Logo from 'components/logo'
import { Paragraph } from 'components/typography'

type PassedProps = RouterState & {
  navigateTo: typeof actions.navigateTo
}

const PageConnect: React.SFC<PassedProps> = (props) => (
  <Layoutable align="center">
    <Logo />
    <H3>Send Better Email</H3>
    <Paragraph paddingVertical="small" color="alphaWhite">
      Whether you need to sell your products, share some big news, or tell a story,
      our campaign builder makes it easy to create email campaigns that best suit your message.
    </Paragraph>
    <Layoutable paddingHorizontal="xxx-large">
      <Layoutable marginTop="x-large">
        <PrimaryButton fullWidth onClick={() => props.navigateTo('connect.mailchimp', {}, { replace: true })}>
          Yes, I have a Mailchimp Account
        </PrimaryButton>
      </Layoutable>
      <Layoutable marginTop="x-large">
        <ContrastButton fullWidth onClick={() => props.navigateTo('connect.new', {}, { replace: true })}>
          Create an Account and come back
        </ContrastButton>
      </Layoutable>
    </Layoutable>
  </Layoutable>
)

// TODO: split out into container/component files
export default connect(
  _state => routeNodeSelector('connect'),
  { navigateTo: actions.navigateTo }
)(PageConnect)
