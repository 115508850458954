import { AxiosResponse } from 'axios'
import { all, put, takeLatest } from 'redux-saga/effects'
import apiClient, { errorFromResponse } from 'src/apiClient'
import actions, { types } from './actions'

type Account = {
  list: {
    id: string
    name: string
  }
  isSynced: boolean
  connectedAt: string
}

export function* callAccount(_action: ReturnType<typeof actions.account.call>) {
  yield put(actions.account.request())

  try {
    const { data }: AxiosResponse<Account> = yield apiClient.get('/api/account')

    yield put(
      actions.account.ok({
        list: data.list,
        isSynced: data.isSynced,
        connectedAt: new Date(data.connectedAt),
      })
    )
  } catch (err) {
    yield put(actions.account.error(errorFromResponse(err)))
  }
}

export function* account() {
  yield takeLatest(types.ACCOUNT, callAccount)
}

export default function* rootSaga() {
  yield all([
    account(),
  ])
}
