import * as React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { DialogProps } from '@material-ui/core/Dialog'
import { StyleRules, WithStyles } from '@material-ui/core/styles'
import {
  Dialog as MaterialDialog,
  DialogTitle as MaterialDialogTitle,
  DialogContent as MaterialDialogContent,
  withMobileDialog,
  IconButton,
  withStyles,
} from '@material-ui/core'

import { Cell, Grid } from 'components/responsive'
import { unit } from '../utils/css'

const StyleDialog: StyleRules = {
  root: {
    fontFamily: 'Lato, sans-serif',
  },
}

const StyleDialogTitle: StyleRules = {
  root: {
    backgroundColor: '#52BAD5',
    color: '#FFFFFF',
    fontWeight: 700,
    lineHeight: unit(24 / 16, 'rem'),
    letterSpacing: '1px',
    textTransform: 'uppercase',
    padding: '14px 24px 10px 24px',
  },
}

const StyleDialogContent: StyleRules = {
  root: {
    padding: `${unit(32 / 16, 'rem')} 16%`,
  },
}

export const Dialog = withStyles(StyleDialog, { name: 'Dialog' })
  ((props: DialogProps & WithStyles<string> & { children?: React.ReactNode }) =>
    (
      <MaterialDialog {...props}>
        {props.children}
      </MaterialDialog>
    ))

export const DialogTitle = withStyles(StyleDialogTitle, { name: 'DialogTitle' })
  ((props: WithStyles<string> & { children?: React.ReactNode }) =>
    (
      <MaterialDialogTitle classes={props.classes} disableTypography>
        {props.children}
      </MaterialDialogTitle>
    ))

export const DialogContent = withStyles(StyleDialogContent, { name: 'DialogContent' })
  ((props: WithStyles<string> & { children?: React.ReactNode }) =>
    (
      <MaterialDialogContent classes={props.classes}>
        {props.children}
      </MaterialDialogContent>
    ))

interface PropsResponsiveDialog {
  fullScreen?: boolean
  title: string
  handleRequestClose?: React.MouseEventHandler<{}>
}

class ResponsiveDialogComponent extends React.PureComponent<PropsResponsiveDialog> {
  render() {
    const { handleRequestClose } = this.props
    return (
      <Dialog
        open
        onClose={handleRequestClose}
        fullScreen={this.props.fullScreen}
      >
        <DialogTitle>
          <Grid alignItems="center">
            <Cell xs={handleRequestClose ? 9 : 12} padding="small">
              {this.props.title}
            </Cell>
            {handleRequestClose &&
              <Cell xs={3} align="right">
                <IconButton color="inherit" onClick={handleRequestClose} aria-label="Close">
                  <CloseIcon style={{ width: '1.5rem', height: '1.5rem' }} />
                </IconButton>
              </Cell>
            }
          </Grid>
        </DialogTitle>
        <DialogContent>
          {this.props.children}
        </DialogContent>
      </Dialog>
    )
  }
}

export const ResponsiveDialog = withMobileDialog<PropsResponsiveDialog>({
  breakpoint: 'xs',
})(ResponsiveDialogComponent)
