import actions, {
  Account,
  Actions,
  types,
} from './actions'

export type AccountState = {
  account?: Account
  error?: Error
}

const initialState: AccountState = {
  account: undefined,
  error: undefined,
}

const reducer = (state: AccountState = initialState, action: Actions): AccountState => {
  const { type } = action
  switch (type) {
    case types.ACCOUNT_REQUEST_ERROR: {
      const act = action as ReturnType<typeof actions.account.error>
      return {
        ...state,
        error: act.payload,
      }
    }
    case types.ACCOUNT_REQUEST_OK: {
      const act = action as ReturnType<typeof actions.account.ok>
      return {
        ...state,
        account: act.payload,
      }
    }
    default:
      return state
  }
}

export default reducer
