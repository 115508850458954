import glamorous from 'glamorous'
import * as React from 'react'
import { unit } from '../utils/css'

// tslint:disable-next-line:no-empty-interface
export interface PropsLogo {
}

export default class Logo extends React.PureComponent<PropsLogo> {
  render() {
    return (
      <LogoDiv>
        <LogoImg src="/img/logo_mailchimp.png" />
      </LogoDiv>
    )
  }
}

const LogoDiv = glamorous.div({
  width: unit(96 / 16, 'rem'),
  height: unit(96 / 16, 'rem'),
  background: 'rgba(0, 0, 0, 0.2)',
  borderRadius: '50%',
  margin: '0px auto 18px',
})

const LogoImg = glamorous.img({
  position: 'relative',
  width: unit(56 / 16, 'rem'),
  height: unit(56 / 16, 'rem'),
  top: '50%',
  transform: 'translateY(-50%)',
})
