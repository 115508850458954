import * as React from 'react'
import Checkbox from '@material-ui/core/Checkbox'

export type PassedProps = {
  disabled: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
  checked: boolean
}

export const PrimaryCheckbox = (props: PassedProps) => (
  <Checkbox
    color="primary"
    style={{ height: 24, width: 24 }}
    disabled={props.disabled}
    onChange={props.onChange}
    checked={props.checked}
  />
)
