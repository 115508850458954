import glamorous, { CSSProperties } from 'glamorous'
import * as React from 'react'
import { unit } from '../utils/css'
import { ComposeLayoutable } from './layoutable'

const Header2 = glamorous.h2({
  fontWeight: 200,
  fontSize: unit(36 / 16, 'rem'),
  lineHeight: unit(2, 'rem'),
  color: '#757575',
})

const Header3 = glamorous.h3({
  fontWeight: 700,
  fontSize: unit(26 / 16, 'rem'),
  lineHeight: unit(32 / 26, 'rem'),
  color: '#ffffff',
})

const Header4 = glamorous.h4(
  {
    fontWeight: 700,
    fontSize: unit(16 / 16, 'rem'),
    lineHeight: unit(24 / 16, 'rem'),
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.868744)',
  },
  (props: PropsHeader): CSSProperties[] => {
    const styles: CSSProperties[] = []

    if (props.secondary) {
      styles.push({
        lineHeight: unit(16 / 16, 'rem'),
        letterSpacing: 'normal',
        textTransform: 'none',
      })
    }

    return styles
  }
)

export interface PropsHeader {
  secondary?: boolean
}

export const H2 = ComposeLayoutable(
  (props: { style: object; children?: React.ReactNode }) => (
    <Header2 style={props.style}>
      {props.children}
    </Header2>
  ))

export const H3 = ComposeLayoutable(
  (props: { style: object; children?: React.ReactNode }) => (
    <Header3 style={props.style}>
      {props.children}
    </Header3>
  ))

export const H4 = ComposeLayoutable<PropsHeader>(
  (props: PropsHeader & { style: object; children?: React.ReactNode }) => (
    <Header4 secondary={props.secondary} style={props.style}>
      {props.children}
    </Header4>
  ))
