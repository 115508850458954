const SESSION = 'SESSION'
const SESSION_REQUEST = 'SESSION_REQUEST'
const SESSION_REQUEST_ERROR = 'SESSION_REQUEST_ERROR'
const SESSION_REQUEST_OK = 'SESSION_REQUEST_OK'

export type Session = {
  tenantId: string
  hasMailchimpAuth: boolean
  authRedirect: string
}

type ActionSession = {
  type: typeof SESSION
}

type ActionSessionRequest = {
  type: typeof SESSION_REQUEST
}

type ActionSessionRequestError = {
  type: typeof SESSION_REQUEST_ERROR
  payload: Error
}

type ActionSessionRequestOk = {
  type: typeof SESSION_REQUEST_OK
  payload: Session
}

export const types = {
  SESSION,
  SESSION_REQUEST,
  SESSION_REQUEST_ERROR,
  SESSION_REQUEST_OK,
}

export type Actions = ActionSession
  | ActionSessionRequest
  | ActionSessionRequestError
  | ActionSessionRequestOk

const actionCreators = {
  session: {
    call: (): ActionSession => ({
      type: SESSION,
    }),
    request: (): ActionSessionRequest => ({
      type: SESSION_REQUEST,
    }),
    error: (error: Error): ActionSessionRequestError => ({
      type: SESSION_REQUEST_ERROR,
      payload: error,
    }),
    ok: (sess: Session): ActionSessionRequestOk => ({
      type: SESSION_REQUEST_OK,
      payload: sess,
    }),
  },
}

export default actionCreators
