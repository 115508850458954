import glamorous from 'glamorous'
import * as React from 'react'

import { ComposeLayoutable } from 'components/layoutable'
import { unit } from 'utils/css'

const BoxWrapper = glamorous.div({
  padding: unit(20 / 16, 'rem'),
  position: 'relative',
  background: '#ffffff',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.24)',
  borderRadius: 2,
  color: '#757575',
})

export default class Box extends React.PureComponent {
  render() {
    return (
      <BoxWrapper>
        {this.props.children}
      </BoxWrapper>
    )
  }
}

const InnerBoxWrapper = glamorous.div({
  background: 'rgba(82, 186, 213, 0.2)',
  padding: '20px',
  textAlign: 'center',
})

export const InnerBox = ComposeLayoutable(
  (props: { style: object; children?: React.ReactNode }) => (
    <InnerBoxWrapper style={props.style}>
      {props.children}
    </InnerBoxWrapper>
  ))
