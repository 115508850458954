import * as React from 'react'
import * as qs from 'query-string'
import { connect } from 'react-redux'
import { routeNodeSelector, actions, RouterState } from 'redux-router5'
import { RootState } from 'src/store'

import { PrimaryButton } from 'src/components/button'
import { Layoutable } from 'src/components/layoutable'
import Loader from 'src/components/loader'
import Logo from 'src/components/logo'
import { Paragraph } from 'src/components/typography'
import { SessionState } from './session'
import sessionActions from './session/actions'

type PageProps = RouterState & {
  session: SessionState
  fetchSession: typeof sessionActions.session.call,
  navigateTo: typeof actions.navigateTo
}

type State = {
  modalWasClosed: boolean
}

class PageConnectMailchimp extends React.Component<PageProps, State> {
  private timerFetchSession?: number
  private timerCheckModal?: number
  private modal?: Window | null
  private isRedirecting: boolean = false
  private sessionRequestCounter = 0

  constructor(props: PageProps) {
    super(props)

    this.state = {
      modalWasClosed: false,
    }
  }

  componentDidMount() {
    this.props.fetchSession()
  }

  componentWillUnmount() {
    if (this.timerFetchSession) {
      clearInterval(this.timerFetchSession)
    }

    if (this.timerCheckModal) {
      clearInterval(this.timerCheckModal)
    }
  }

  componentWillReceiveProps(nextProps: PageProps) {
    this.handlePropsUpdate(nextProps)
  }

  render() {
    return (
      <Layoutable align="center">
        <Logo />
        <Paragraph paddingVertical="small" color="alphaWhite">
          Awaiting Mailchimp Authorization...
        </Paragraph>
        <Layoutable paddingHorizontal="xxx-large">
          {this.renderErrorState()}
        </Layoutable>
      </Layoutable>
    )
  }

  renderErrorState() {
    if (this.modal === null ||
      (this.state.modalWasClosed && this.props.session && !this.props.session.hasMailchimpAuth)) {
      return (
        <Layoutable>
          <Paragraph paddingVertical="small" color="alphaWhite">
            Didn't see a way to login to your MailChimp account? Please ensure pop-ups are enabled and try again.
          </Paragraph>
          <Layoutable marginTop="x-large">
            <PrimaryButton fullWidth onClick={this.handleRetry}>
              Retry
            </PrimaryButton>
          </Layoutable>
        </Layoutable>
      )
    }

    return (
      <Loader />
    )
  }

  handleRetry = () => {
    if (this.modal) {
      this.modal.close()
      this.modal = null
    }

    this.sessionRequestCounter = 0
    clearTimeout(this.timerFetchSession)
    clearInterval(this.timerCheckModal)
    this.timerCheckModal = undefined
    this.timerFetchSession = undefined
    this.handleCreatePopup()
  }

  handleCreatePopup = () => {
    if (!this.modal) {
      const query = qs.stringify(this.props.route!.params)
      const url = `${window.location.origin}/auth/mailchimp?${query}`
      this.createPopup(url, 'MailChimpOAuth')
    }

    if (this.modal && !this.timerFetchSession) {
      this.timerFetchSession = window.setTimeout(this.fetchSession, 1000)
    }
  }

  handlePropsUpdate = (props: PageProps) => {
    if (props.session.isLoading) {
      return
    }

    if (!this.isRedirecting && props.session.hasMailchimpAuth && props.session.authRedirect) {
      if (this.modal) {
        this.modal.close()
      }
      this.isRedirecting = true
      const nextPath = props.session.authRedirect.substr(1).replace(/\//g, '.')
      props.navigateTo(nextPath, {}, { replace: true })
      return
    }

    if (!this.state.modalWasClosed) {
      this.handleCreatePopup()
    }

    if (this.modal && !this.modal.closed && !this.timerFetchSession) {
      this.timerFetchSession = window.setTimeout(this.fetchSession, 1000)
    }

    const closed = this.modal ? this.modal.closed : this.state.modalWasClosed
    if (closed && !this.timerFetchSession) {
      this.timerFetchSession = window.setTimeout(this.fetchSession, 500)
      this.sessionRequestCounter += 1
    }
  }

  createPopup = (url: string, title: string, w: number = 800, h: number = 800) => {
    if (this.modal) {
      this.modal.focus()
      return
    }

    let x
    let y

    try {
      x = window.top.screenX + (window.top.outerWidth / 2) - (w / 2)
      y = window.top.screenY + (window.top.outerHeight / 2) - (h / 2)
    } catch {
      x = window.screenX + (window.outerWidth / 2) - (w / 2)
      y = window.screenY + (window.outerHeight / 2) - (h / 2)
    }

    this.modal = window.open(url, title, [
      'toolbar=no',
      'location=no',
      'directories=no',
      'status=no',
      'menubar=no',
      'scrollbars=no',
      'resizable=no',
      'copyhistory=no',
      'width=' + w,
      'height=' + h,
      'top=' + y,
      'left=' + x,
    ].join(', '))

    this.timerCheckModal = window.setInterval(
      () => {
        if (!this.modal || this.modal.closed) {
          clearInterval(this.timerCheckModal)
          this.setState({ modalWasClosed: true })
        }
      },
      500
    )
  }

  fetchSession = () => {
    this.timerFetchSession = undefined
    if (this.sessionRequestCounter < 5) {
      this.props.fetchSession()
    }
  }
}

export default connect(
  (state: RootState) => ({
    session: state.session,
    ...routeNodeSelector('connect.mailchimp')(state),
  }),
  {
    fetchSession: sessionActions.session.call,
    navigateTo: actions.navigateTo,
  }
)(PageConnectMailchimp)
