import * as React from 'react'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import { CircularProgress, StyleRulesCallback } from '@material-ui/core'

const styles: StyleRulesCallback = theme => ({
  root: {
    width: '20px',
    height: '100%',
    marginLeft: theme.spacing.unit,
  },
})

const Circular = (props: WithStyles<string>) => {
  const { classes } = props
  return (
    <CircularProgress classes={classes} color="inherit" size={26} thickness={2.5} />
  )
}

export default withStyles(styles)(Circular)
