import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import * as React from 'react'

import { ComposeLayoutable } from './layoutable'

export interface PropsTextField extends TextFieldProps {
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  fullWidth?: boolean
  placeholder?: string
}

export default ComposeLayoutable<PropsTextField>(TextField)
