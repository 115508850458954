import { CircularProgress } from '@material-ui/core'
import * as React from 'react'

import { Layoutable, PropsLayoutable } from 'components/layoutable'

export default class Loader extends React.Component<PropsLayoutable> {
  render() {
    return (
      <Layoutable align="center" padding="xxx-large">
        <CircularProgress />
      </Layoutable>
    )
  }
}
