const LIST = 'LIST'
const LIST_REQUEST = 'LIST_REQUEST'
const LIST_REQUEST_ERROR = 'LIST_REQUEST_ERROR'
const LIST_REQUEST_OK = 'LIST_REQUEST_OK'
const CONNECT = 'LIST_SELECT'
const CONNECT_REQUEST = 'LIST_SELECT_REQUEST'
const CONNECT_REQUEST_ERROR = 'LIST_SELECT_REQUEST_ERROR'
const CONNECT_REQUEST_OK = 'LIST_SELECT_REQUEST_OK'

export type ShopperList = {
  id: string
  name: string
}

type ActionList = {
  type: typeof LIST
}

type ActionListRequest = {
  type: typeof LIST_REQUEST
}

type ActionListRequestError = {
  type: typeof LIST_REQUEST_ERROR
  payload: Error
}

type ActionListRequestOk = {
  type: typeof LIST_REQUEST_OK
  payload: ReadonlyArray<ShopperList>
}

type ActionConnect = {
  type: typeof CONNECT
  payload: ShopperList & {
    syncShoppers: boolean
  }
}

type ActionConnectRequest = {
  type: typeof CONNECT_REQUEST
}

type ActionConnectRequestError = {
  type: typeof CONNECT_REQUEST_ERROR
  payload: Error
}

type ActionConnectRequestOk = {
  type: typeof CONNECT_REQUEST_OK
}

export const types = {
  LIST,
  LIST_REQUEST,
  LIST_REQUEST_ERROR,
  LIST_REQUEST_OK,
  CONNECT,
  CONNECT_REQUEST,
  CONNECT_REQUEST_ERROR,
  CONNECT_REQUEST_OK,
}

export type Actions = ActionList
  | ActionListRequest
  | ActionListRequestError
  | ActionListRequestOk
  | ActionConnect
  | ActionConnectRequest
  | ActionConnectRequestError
  | ActionConnectRequestOk

const actionCreators = {
  lists: {
    call: (): ActionList => ({
      type: LIST,
    }),
    request: (): ActionListRequest => ({
      type: LIST_REQUEST,
    }),
    error: (error: Error): ActionListRequestError => ({
      type: LIST_REQUEST_ERROR,
      payload: error,
    }),
    ok: (list: ReadonlyArray<ShopperList>): ActionListRequestOk => ({
      type: LIST_REQUEST_OK,
      payload: list,
    }),
  },
  connect: {
    call: (list: ShopperList, syncShoppers: boolean): ActionConnect => ({
      type: CONNECT,
      payload: {
        ...list,
        syncShoppers,
      },
    }),
    request: (): ActionConnectRequest => ({
      type: CONNECT_REQUEST,
    }),
    error: (error: Error): ActionConnectRequestError => ({
      type: CONNECT_REQUEST_ERROR,
      payload: error,
    }),
    ok: (): ActionConnectRequestOk => ({
      type: CONNECT_REQUEST_OK,
    }),
  },
}

export default actionCreators
