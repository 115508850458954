import * as moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import { RouterState, routeNodeSelector } from 'redux-router5'
import glamorous from 'glamorous'

import { RootState } from 'src/store'
import accountActions, { Account } from './actions'

import Box from 'src/components/box'
import { PrimaryButton, Button } from 'src/components/button'
import { ResponsiveDialog } from 'src/components/dialog'
import { H2, H4 } from 'src/components/headers'
import { Layoutable } from 'src/components/layoutable'
import Loader from 'src/components/loader'
import { Cell, Grid } from 'src/components/responsive'
import { Paragraph, Span } from 'src/components/typography'

const mailchimpFeatures = [
  'Collect shoppers’ contact info',
  'Create abandoned cart emails',
  'Gather product reviews',
  'Send product recommendations',
  '...and more!',
]

type SyncDialogProps = {
  onStore: React.MouseEventHandler<{}>;
  onMailchimp: React.MouseEventHandler<{}>;
}

export const SyncDialogFragment = (props: SyncDialogProps) => {
  const { onMailchimp, onStore } = props
  return (
    <>
      <Layoutable align="center" marginBottom="xx-large">
        <img src="/img/icon-loop.svg" alt="Loop" />
      </Layoutable>
      <H2 align="center">Your Store is Syncing!</H2>
      <Paragraph color="gray" marginTop="x-large" marginBottom="medium" align="center">
        Syncing might take a few minutes–but no need to wait here!
      </Paragraph>
      <Layoutable align="center">
        <Span color="gray" bold>
          When finished, you’ll be able to:
        </Span>
        <Span color="gray" bold>
          {mailchimpFeatures.join(' • ')}
        </Span>
      </Layoutable>
      <Grid alignItems="center" marginTop="xxx-large">
        <Cell sm={6} xs={12} align="center" marginBottom="small" paddingHorizontal="small">
          <Button onClick={onMailchimp} fullWidth>
            Explore MailChimp
          </Button>
        </Cell>
        <Cell sm={6} xs={12} align="center" marginBottom="small" paddingHorizontal="small">
          <PrimaryButton onClick={onStore} fullWidth>
            Back to My Store
          </PrimaryButton>
        </Cell>
      </Grid>
    </>
  )
}

const SyncDialog = (props: SyncDialogProps) => (
  <ResponsiveDialog title="Connect with MailChimp">
    <SyncDialogFragment {...props} />
  </ResponsiveDialog>
)

const SyncDialogMobile = (props: SyncDialogProps) => (
  <Box>
    <SyncDialogFragment {...props} />
  </Box>
)

type PageProps = RouterState & {
  account?: Account
  fetchAccount: typeof accountActions.account.call
}

const LogoImg = glamorous.img({
  position: 'relative',
  width: '114px',
  height: '114px',
})

class PageSettings extends React.Component<PageProps> {
  componentDidMount() {
    this.props.fetchAccount()
  }

  render() {
    const { account } = this.props
    if (!account) {
      return (
        <Box>
          <Loader />
        </Box>
      )
    }

    if (!account.isSynced
      && window.screen.availWidth <= 375
      && window.devicePixelRatio > 1) {
      return (
        <SyncDialogMobile
          onStore={this.handleNavStore}
          onMailchimp={this.handleNavMailchimp}
        />
      )
    }

    return (
      <Box>
        {!account.isSynced && (
          <SyncDialog
            onStore={this.handleNavStore}
            onMailchimp={this.handleNavMailchimp}
          />
        )}

        <Grid>
          <Cell sm={7} xs={12}>
            <H4>CONFIGURE MAILCHIMP</H4>
            <Paragraph>
              You are ready to edit your MailChimp integration. Now with Mailchimp
              for Volusion, you can:
            </Paragraph>
            <ul
              style={{
                listStyle: 'initial',
                color: 'gray',
                marginLeft: '1em',
                marginTop: '1em',
              }}
            >
              {mailchimpFeatures.map(item =>
                <li key={item}>
                  <Span color="gray" bold size="medium">{item}</Span>
                </li>
              )}
            </ul>
          </Cell>
          <Cell sm={5} xs={12} align="center" marginTop="large">
            <Grid direction="column" justify="center">
              <Cell>
                <LogoImg src="/img/logo_mailchimp.png" />
              </Cell>
              <Span marginTop="large">
                <PrimaryButton onClick={this.handleNavMailchimp}>
                  go to mailchimp
                </PrimaryButton>
              </Span>
              <Span marginTop="medium" size="small">
                Volusion store connected since {moment(account.connectedAt).format('MMMM Do, YYYY')}
              </Span>
            </Grid>
          </Cell>
        </Grid>
      </Box>
    )
  }

  handleNavMailchimp = () => {
    window.open('https://mailchimp.com/features/', '_blank')
  }

  handleNavStore = () => {
    window.parent.postMessage({ state: 'dashboard' }, '*')
  }
}

const mapStateToProps = (state: RootState) => {
  const selector = routeNodeSelector('settings')
  return {
    ...selector(state),
    ...state.account,
  }
}

export default connect(mapStateToProps, {
  fetchAccount: accountActions.account.call,
})(PageSettings)
