import * as React from 'react'
import * as ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'
import { RouterState, routeNodeSelector, actions } from 'redux-router5'

import { PrimaryCheckbox } from 'src/components/checkbox'
import Box from 'src/components/box'
import { PrimaryButton, SecondaryButton } from 'src/components/button'
import { H4 } from 'src/components/headers'
import { Layoutable } from 'src/components/layoutable'
import Loader from 'src/components/loader'
import { Cell, Grid } from 'src/components/responsive'
import Select, { SelectOptions } from 'src/components/select'
import TextField from 'src/components/textfield'
import { Paragraph, Span } from 'src/components/typography'
import Progress from 'src/components/progress'
import { RootState } from 'src/store'
import listActions, { ShopperList } from './actions'
import { ListState } from './reducer'

const defaultListItems = [
  {
    id: '-new-',
    name: (<span style={{ fontStyle: 'italic' }}>- Create New List -</span>),
  },
]

type PassedProps = RouterState & ListState & {
  navigateTo: typeof actions.navigateTo
  fetchList: typeof listActions.lists.call
  connectList: typeof listActions.connect.call
}

type State = {
  selection: ShopperList
  syncCustomers: boolean
}

class PageConnectList extends React.Component<PassedProps, State> {
  constructor(props: PassedProps) {
    super(props)

    this.state = {
      selection: { id: '-new-', name: '' },
      syncCustomers: false,
    }
  }

  componentDidMount() {
    this.props.fetchList()
  }

  shouldComponentUpdate(nextProps: PassedProps, _nextState: State) {
    if (nextProps.isConnected) {
      nextProps.navigateTo('settings', {}, { replace: true })
      return false
    }
    return true
  }

  render() {
    const { error, isConnecting } = this.props
    const { selection } = this.state

    return (
      <Box>
        <H4 marginTop="small">
          Choose a list to sync
        </H4>
        <Paragraph paddingTop="small">
          Customers who agreed to receive promotional emails from your
          store will be added to this list.
        </Paragraph>
        {this.renderList()}
        <ReactCSSTransitionGroup
          transitionName="example"
          transitionEnterTimeout={250}
          transitionLeaveTimeout={250}
        >
          {error && selection.id !== '-new-' && (
            <Layoutable>
              <Paragraph paddingTop="small" color="error">
                {error.message}
              </Paragraph>
            </Layoutable>
          )}
        </ReactCSSTransitionGroup>
        <Grid alignItems="center" marginTop="x-large">
          <Cell xs={6}>
            <SecondaryButton onClick={this.handleCancel}>Cancel</SecondaryButton>
          </Cell>
          <Cell xs={6} align="right">
            {isConnecting
              ? <PrimaryButton disabled>
                syncing <Progress />
              </PrimaryButton>
              : <PrimaryButton onClick={this.handleNext} disabled={!this.props.lists}>
                next
              </PrimaryButton>
            }
          </Cell>
        </Grid>
      </Box>
    )
  }

  renderList() {
    const { lists, isConnecting, error } = this.props
    if (!lists && !error) {
      return <Loader />
    }

    const { selection, syncCustomers } = this.state
    const list = [...defaultListItems, ...(lists || [])]
    const options: SelectOptions = list.map((entry) => (
      ({
        value: entry.id,
        label: entry.name,
      }))
    )

    return (
      <Layoutable paddingTop="medium">
        <Layoutable paddingBottom="xxx-large">
          <Select
            options={options}
            onChange={this.handleSelection}
            value={selection.id}
            error={!lists && error}
            disabled={isConnecting}
          />
        </Layoutable>
        {selection.id === '-new-' && (
          <Layoutable paddingBottom="small">
            <TextField
              fullWidth
              placeholder="List Name"
              label={error && error.message}
              value={selection.name}
              onChange={this.handleNameUpdate}
              error={!!error}
            />
          </Layoutable>
        )}
        <label
          style={{
            display: 'block',
            alignItems: 'center',
            position: 'relative',
            marginTop: '10px',
          }}
        >
          <PrimaryCheckbox
            disabled={isConnecting}
            onChange={this.handleCheckboxCheck}
            checked={syncCustomers}
          />
          <Span valign="middle" marginLeft="xxx-small">
            Sync my Volusion Customer List
          </Span>
        </label>
      </Layoutable>
    )
  }

  handleCheckboxCheck = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      syncCustomers: target.checked,
    })
  }

  handleNameUpdate = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      selection: { id: '-new-', name: target.value },
    })
  }

  handleSelection = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      selection: { id: target.value, name: '' },
    })
  }

  handleNext = () => {
    const { selection: { id, name }, syncCustomers } = this.state
    const finalName = id === '-new-'
      ? name
      : this.props.lists!.find(({ id: anId }) => (id === anId))!.name
    const list = { id, name: finalName }
    this.props.connectList(list, syncCustomers)
  }

  handleCancel = () => {
    this.props.navigateTo('connect', {}, { replace: true })
  }
}

const mapStateToProps = (state: RootState) => {
  const selector = routeNodeSelector('connect.list')
  return {
    ...selector(state),
    ...state.list,
  }
}

export default connect(
  mapStateToProps,
  {
    navigateTo: actions.navigateTo,
    fetchList: listActions.lists.call,
    connectList: listActions.connect.call,
  }
)(PageConnectList)
