import MaterialGrid, { GridProps } from '@material-ui/core/Grid'
import MaterialHidden, { HiddenProps } from '@material-ui/core/Hidden'
import * as React from 'react'
import { ComposeLayoutable, PropsLayoutable } from './layoutable'

export const Hidden = ComposeLayoutable<HiddenProps>(MaterialHidden)
export const GridComponent = ComposeLayoutable<GridProps>(MaterialGrid)

export const Grid = (props: PropsLayoutable & GridProps) =>
  <GridComponent container spacing={0} {...props} />

export const Cell = (props: PropsLayoutable & GridProps) =>
  <GridComponent item {...props} />
