import * as React from 'react'
import glamorous from 'glamorous'
import { unit } from '../utils/css'

declare type PropVerticalAlign = false | 'top' | 'middle' | 'bottom'
declare type PropHorizontalAlign = false | 'left' | 'center' | 'right'
declare type PropSize = false |
  'none' |
  'xxx-small' |
  'xx-small' |
  'x-small' |
  'small' |
  'medium' |
  'large' |
  'x-large' |
  'xx-large' |
  'xxx-large'

const sizeMap: { [key: string]: any } = {
  'xxx-small': unit(3 / 14, 'rem'),   // 2.57px(@12px)  3px(@14px)
  'xx-small': unit(6 / 14, 'rem'),    // 5.14px(@12px)  6px(@14px)
  'x-small': unit(9 / 14, 'rem'),     // 7.71px(@12px)  9px(@14px)
  small: unit(10 / 14, 'rem'),        // 8.57px(@12px)  10px(@14px)
  medium: unit(11 / 14, 'rem'),       // 9.43px(@12px)  11px(@14px)
  large: unit(13 / 14, 'rem'),        // 11.14px(@12px) 13px(@14px)
  'x-large': unit(15 / 14, 'rem'),    // 12.86px(@12px) 15px(@14px)
  'xx-large': unit(20 / 14, 'rem'),   // 17.14px(@12px) 20px(@14px)
  'xxx-large': unit(30 / 14, 'rem'),  // 25.71px(@12px) 30px(@14px)
}

export interface PropsLayoutable {
  align?: PropHorizontalAlign,
  valign?: PropVerticalAlign,
  padding?: PropSize,
  paddingVertical?: PropSize,
  paddingHorizontal?: PropSize,
  paddingTop?: PropSize,
  paddingBottom?: PropSize,
  paddingLeft?: PropSize,
  paddingRight?: PropSize,
  margin?: PropSize,
  marginVertical?: PropSize,
  marginHorizontal?: PropSize,
  marginTop?: PropSize,
  marginBottom?: PropSize,
  marginLeft?: PropSize,
  marginRight?: PropSize
}

// key-based prop lookup chart for fast filtering
const layoutableProps = {
  align: true,
  valign: true,
  padding: true,
  paddingVertical: true,
  paddingHorizontal: true,
  paddingTop: true,
  paddingBottom: true,
  paddingLeft: true,
  paddingRight: true,
  margin: true,
  marginVertical: true,
  marginHorizontal: true,
  marginTop: true,
  marginBottom: true,
  marginLeft: true,
  marginRight: true,
}

const getContextualProps = (props: PropsLayoutable, keys: string[], contexts?: any[]) => {
  let result: any | undefined

  for (const prop of keys) {
    result = [props]
      .concat(contexts || [])
      .reduce(
        (memo, val) => {
          memo.push(val[prop])

          return memo
        },
        [] as any[]
      )[0]

    // NOTE: We have specific use-cases for falsey values
    // - `false` is treated as undefined since we use shortcuts such as padding={boolean && "small"}
    // - "none" is a real false (IE padding="xx-large" paddingLeft="none" to clear/reset left padding)
    if (result === 'none') {
      result = false
    } else if (result === false) {
      result = undefined
    }

    if (result !== undefined) {
      return result
    }
  }

  return result
}

export const layoutableStyle = (props: PropsLayoutable, style?: React.CSSProperties): React.CSSProperties => {
  const result: React.CSSProperties = {
    position: 'relative',
    ...style,
  }
  const align = getContextualProps(props, ['align'])
  const valign = getContextualProps(props, ['valign'])
  const padTop = getContextualProps(props, ['paddingTop', 'paddingVertical', 'padding'])
  const padBottom = getContextualProps(props, ['paddingBottom', 'paddingVertical', 'padding'])
  const padLeft = getContextualProps(props, ['paddingLeft', 'paddingHorizontal', 'padding'])
  const padRight = getContextualProps(props, ['paddingRight', 'paddingHorizontal', 'padding'])
  const marginTop = getContextualProps(props, ['marginTop', 'marginVertical', 'margin'])
  const marginBottom = getContextualProps(props, ['marginBottom', 'marginVertical', 'margin'])
  const marginLeft = getContextualProps(props, ['marginLeft', 'marginHorizontal', 'margin'])
  const marginRight = getContextualProps(props, ['marginRight', 'marginHorizontal', 'margin'])

  if (align) {
    result.textAlign = align
  }

  if (valign) {
    result.verticalAlign = valign
  }

  result.paddingTop = sizeMap[padTop]
  result.paddingBottom = sizeMap[padBottom]
  result.paddingLeft = sizeMap[padLeft]
  result.paddingRight = sizeMap[padRight]
  result.marginTop = sizeMap[marginTop]
  result.marginBottom = sizeMap[marginBottom]
  result.marginLeft = sizeMap[marginLeft]
  result.marginRight = sizeMap[marginRight]

  return result
}

const mungeProps = (props: object): object => {
  const copy = {
    style: layoutableStyle(props),
  }
  const keys = Object.keys(props)

  for (const key of keys) {
    if (layoutableProps[key]) {
      continue
    }
    copy[key] = props[key]
  }

  return copy
}

const LayoutableContainer = glamorous.div({
  '@media only screen and (max-width : 767px) and (-webkit-min-device-pixel-ratio: 2)': {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
})

export const ComposeLayoutable = function <P>(component: React.ComponentType<any>) {
  return (props: P & PropsLayoutable & { children?: React.ReactNode }) =>
    React.createElement(component, mungeProps(props))
}

export const Layoutable = (props: PropsLayoutable & { className?: string; children?: React.ReactNode }) =>
  (
    <LayoutableContainer {...mungeProps(props)} />
  )
