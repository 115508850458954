import * as React from 'react'

import { PrimaryButton } from 'src/components/button'
import { H3 } from 'src/components/headers'
import { Layoutable } from 'src/components/layoutable'
import Logo from 'src/components/logo'
import { Paragraph } from 'src/components/typography'

export default class PageModal extends React.Component {
  componentDidMount() {
    window.close()
  }

  render() {
    return (
      <Layoutable align="center">
        <Logo />
        <H3>Send Better Email</H3>
        <Paragraph paddingVertical="small" color="alphaWhite">
          Mailchimp Authorization Error encountered.
        </Paragraph>
        <Paragraph paddingVertical="small" color="alphaWhite">
          You encountered an unexpected error during your authentication with Mailchimp.
          Please close this window to return back to the account setup and try again.
        </Paragraph>
        <Layoutable paddingHorizontal="xxx-large">
          <Layoutable marginTop="x-large">
            <PrimaryButton fullWidth onClick={window.close}>
              Back to Setup
            </PrimaryButton>
          </Layoutable>
        </Layoutable>
      </Layoutable>
    )
  }
}
