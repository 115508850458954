import actions, {
  Actions,
  types,
} from './actions'

export type SessionState = {
  tenantId: string
  hasMailchimpAuth: boolean
  authRedirect: string
  isLoading: boolean
}

const initialState: SessionState = {
  tenantId: '',
  hasMailchimpAuth: false,
  authRedirect: '',
  isLoading: true,
}

const reducer = (state: SessionState = initialState, action: Actions) => {
  const { type } = action
  switch (type) {
    case types.SESSION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case types.SESSION_REQUEST_ERROR: {
      const act = action as ReturnType<typeof actions.session.error>
      return {
        ...state,
        error: act.payload,
        isLoading: false,
      }
    }
    case types.SESSION_REQUEST_OK: {
      const act = action as ReturnType<typeof actions.session.ok>
      return {
        ...state,
        ...act.payload,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export default reducer
