import * as React from 'react'
import { StyleRules, WithStyles } from '@material-ui/core/styles'
import {
  FormControl,
  FormHelperText,
  Input,
  MenuItem,
  Select,
  withStyles,
} from '@material-ui/core'

import { unit } from '../utils/css'
import { layoutableStyle, PropsLayoutable } from './layoutable'

export type SelectOptionValue = string | number
export type SelectOptions<TValue = SelectOptionValue> = Array<SelectOption<TValue>>
export interface SelectOption<TValue = SelectOptionValue> {
  label: React.ReactChild
  value: TValue
  disabled?: boolean
}

export interface PropsSelect extends PropsLayoutable {
  label?: string
  options?: SelectOptions
  onChange?: React.ChangeEventHandler<HTMLSelectElement | HTMLInputElement>
  value: SelectOptionValue
  disabled?: boolean
  style?: React.CSSProperties
}

const StyleFormControl: StyleRules = {
  root: {
    width: '100%',
  },
}

const styles: StyleRules = {
  root: {
    border: '1px solid #BDBDBD',
    borderRadius: unit(3 / 12, 'rem'),
  },
  selectMenu: {
    paddingLeft: '5px',
    '&:focus': {
      background: 'white',
      borderRadius: unit(3 / 12, 'rem'),
    },
  },
}

type PassedProps = { error: boolean; children?: React.ReactNode }
const StyledFormControl = withStyles(StyleFormControl, { name: 'FormControl' })
  <PassedProps>((props: WithStyles<string> & PassedProps) =>
    (
      <FormControl classes={props.classes} error={props.error}>
        {props.children}
      </FormControl>
    ))

const noValue = Number.POSITIVE_INFINITY

export default withStyles(styles, { name: 'Select' })
  ((props: PropsSelect & WithStyles<string> & {
    children?: React.ReactNode;
    error?: any;
  }) =>
    (
      <StyledFormControl error={Boolean(props.error)}>
        <Select
          disableUnderline
          displayEmpty
          classes={props.classes}
          value={props.value || noValue}
          style={layoutableStyle(props, props.style)}
          input={<Input id="age-simple" />}
          onChange={props.onChange}
          disabled={props.disabled}
        >
          {props.label && (
            <MenuItem disabled={true} value={noValue}>
              {props.label}
            </MenuItem>
          )}
          {(props.options && props.options.map((option, index) =>
            (
              <MenuItem key={index} disabled={Boolean(option.disabled)} value={option.value}>
                {option.label}
              </MenuItem>
            )
          ))}
        </Select>
        {Boolean(props.error) && (
          <FormHelperText>{props.error}</FormHelperText>
        )}
      </StyledFormControl>
    ))
