import * as React from 'react'
import { connect } from 'react-redux'
import { RouterState, routeNodeSelector } from 'redux-router5'

// content components
import Connect from 'src/pages/connect/landing'
import Mailchimp from 'src/pages/connect/mailchimp'
import List from 'src/pages/connect/list'
import New from 'src/pages/connect/new'
import NotFound from 'src/pages/404'

const components = {
  mailchimp: Mailchimp,
  list: List,
  new: New,
}

const RoutedComponent: React.SFC<RouterState> = (props) => {
  const { route } = props
  const segments = route!.name.split('.')
  if (segments.length === 1) {
    return <Connect />
  }
  const Component = components[segments[1]] || NotFound
  return <Component />
}

export default connect(_state => routeNodeSelector('connect'))(RoutedComponent)
