import { createStore, applyMiddleware, compose } from 'redux'
import { router5Middleware } from 'redux-router5'
import { Router } from 'router5'
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga'
import { RootReducer, RootState } from './rootReducer'

import { session } from 'src/pages/connect/mailchimp/session/sagas'
import list from 'src/pages/connect/list/sagas'
import account from 'src/pages/settings/sagas'

const configureStore = (router: Router) => {
  const enhancers = []
  const middlewares = [
    router5Middleware(router),
    createSagaMiddleware(),
  ]

  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension())
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middlewares),
    ...enhancers
  )

  const store = createStore<any>(
    RootReducer,
    composedEnhancers
  )

  // start sagas
  const sagaMiddleware = middlewares[1] as SagaMiddleware<undefined>
  sagaMiddleware.run(session)
  sagaMiddleware.run(list)
  sagaMiddleware.run(account)

  return store
}

export {
  configureStore as default,
  RootState,
}
